import { defineStore } from 'pinia'
import { v4 as uuidv4 } from 'uuid'
import UserService from '~~/home/services/user/UserService'
import RoutesConstants from '~~/home/types/route'

// route.global.js에서 호출됙는 중
export const useAuthStore = defineStore('auth', () => {
	const router = useRouter()

	const deviceId = ref('')
	const sessionId = ref('')
	const loggedIn = ref(false)
	const passwordEntered = ref(false)
	const mfaType = ref('')
	const expire = ref(0)
	const { Alert } = useDialogs()

	// 초기 설정
	const initAuth = ref({
		appName: '앱이름',
		addService: [] // 등록할 앱 선택
	})

	// watch(
	// 	loggedIn,
	// 	(val) => {
	// 		console.log('🌐🌐🌐🌐🌐 로그인 상태 loggedIn : ', val)
	// 	},
	// 	{ immediate: true }
	// )

	function restore() {
		deviceId.value = uuidv4()
		// await _checkSession()
		// await isLoggedIn()
	}

	function resetDeviceId() {
		deviceId.value = uuidv4()
	}

	function setLoggedIn(v: boolean) {
		loggedIn.value = v
	}

	function setPasswordEntered(v: boolean) {
		passwordEntered.value = v
	}

	function setMfaType(v: string) {
		mfaType.value = v
	}

	function setExpire(v: number) {
		expire.value = v
	}

	async function checkSession() {
		// LOGIN 확인
		try {
			const { setUserData } = useUserStore()
			const userService = new UserService()
			const res = await userService.getUnauthSession()

			if (res.code !== 200) {
				await logout()
				return
			}
			console.log('🟢 SESSION CHECK : ', res.data)

			const userData = {
				name: res.data?.name ?? '',
				phoneNumber: res.data?.phoneNumber ?? '',
				email: res.data?.email ?? '',
				companyName: res.data?.companyName ?? '',
				subscribeYn: res.data?.subscribeYn ?? ''
			}

			setUserData(userData)
			setMfaType(res.data?.mfaType ?? 'sms')
			setLoggedIn(res.data?.loginYn === 'Y')
			setPasswordEntered(res.data?.passwordCheckYn === 'Y')
		} catch (e) {
			console.error('🔴 SESSION CHECK ERROR : isLoggedIn 함수 ', e)
			await logout()
		}
	}

	async function isUserAuthSession(): Promise<boolean> {
		try {
			await checkSession()
			if (!loggedIn.value) {
				await Alert('세션이 만료되어 로그 아웃되었습니다.')
				await logout()
			} else if (!passwordEntered.value) {
				await Alert('10분간 활동이 없어 자동 로그아웃되었습니다.<br /> 다시 로그인해 주세요.')
				await router.push({ name: RoutesConstants.MY_INDEX })
			}
			return passwordEntered.value
		} catch (e) {
			return false
		}
	}

	async function logout() {
		setLoggedIn(false)
		setPasswordEntered(false)
		// await router.push({ name: RoutesConstants.AUTH_LOGIN })
		return
	}

	return {
		initAuth,
		loggedIn,
		sessionId,
		deviceId,
		expire,
		setExpire,
		setMfaType,
		setLoggedIn,
		setPasswordEntered,
		restore,
		resetDeviceId,
		checkSession,
		logout,
		passwordEntered,
		mfaType,
		isUserAuthSession
	}
})
