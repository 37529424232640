import type { H3Event } from 'h3'
import { appendResponseHeader } from 'h3'
import { defu } from 'defu'
import { useAuthStore } from '~/stores/auth'
import RoutesConstants from '~~/home/types/route'

export default defineNuxtPlugin({
	name: 'http-plugin',
	async setup(nuxtApp) {
		const authStore = useAuthStore()
		const $route = useRoute()

		const RQID = (seq: string) => {
			const now = new Date()
			const y = now.getFullYear()
			const m = now.getMonth() + 1
			const d = now.getDate()
			const h = now.getHours()
			const min = now.getMinutes()
			const sec = now.getSeconds()
			const service = String($route.name).startsWith('admin') ? 'M' : 'U'
			const nano = Math.floor(Math.random() * 100000000)
			const rqid = `${y}${m}${d}${h}${min}${sec}_${nano}_${service}${seq}`
			return rqid
		}

		const defaultHeaders = {
			'Content-Type': 'application/json',
			// 'X-IDAAS-SESSION-ID': authStore.sessionId, // 09.06 세션 아이디는 알아서 들어가는거 아닌가?
			'X-IDAAS-RQID': RQID('001'),
			timeout: 10000,
			retry: false,
			credentials: 'include'
			// cookie: cookies.cookie,
		}

		const uploadHeaders = {
			'Content-Type': 'multipart/form-data',
			// 'X-IDAAS-SESSION-ID': authStore.sessionId, // 09.06 세션 아이디는 알아서 들어가는거
			'X-IDAAS-RQID': RQID('001'),
			timeout: 10000,
			retry: false,
			credentials: 'include'
			// cookie: cookies.cookie,
		}
		// '0222024521_11223344_S001'
		// "발생월일시분초 (10자리)
		// 나노아이디 (8자리)
		// 서비스구분 (1자리)
		// S : 서비스웹
		// M : 관리자 대시보드
		// U : 사용자 대시보드
		// A : U+관리자
		// 3자리 시퀀스번호 (3자리)"

		// const isDev = useRuntimeConfig().public.isDev
		const throwApiError = (code: number, msg: string) => {
			console.log('---throwApiError1:', code, msg)
			const err = new Error(msg)
			err.code = code
			console.log('---throwApiError2:', err)
			throw err
		}

		const mergeOptions = (url: string, opts: any, isUpload: boolean | undefined) => {
			const defaults: any = {
				headers: isUpload ? uploadHeaders : defaultHeaders,
				retry: false,
				timeout: 20000, // 무료 청약 시 최소 10초 딜레이됨... (IAM - 테넌트 생성작업
				retryDelay: 0,
				baseURL: useRuntimeConfig().app.baseURL,
				serverApi: useRuntimeConfig().app.serverApi,
				credentials: 'include',

				async onRequest({ request, options }) {
					console.log('---onRequest:', request, options)
					console.log('-- request.url : ', request.url)
					console.log('-- url : ', url)
					console.log('-- env : ', useRuntimeConfig().public.serverApi)
				},
				async onRequestError({ request, options, error }) {
					console.log('---onRequestError:', error)
					// throw new Error(error)
				},
				async onResponse({ response, options }) {
					console.log('---onResponse---!!!:', response, options)
					console.log('-- response.url : ', response.url)

					if (response.status === 200 || response.status === 302 || response.status === 307) {
						if (response.redirected) {
							window.location.href = response.url
							return
						}
						let { code, msg } = response._data
						if (!code) {
							const data = response._data
							code = 200
							msg = 'temp'
							response._data = { code, msg, data }
						}
						if (!code && !msg) {
							throwApiError(500, '서버에서 요청하신 정보를 가져올 수 없습니다.')
						} else {
							if (code === 200) {
							} else {
								// await Alert(msg + '\n(' + code + ')' || '서버에서 요청하신 정보를 가져올 수 없습니다!')
								// throw new Error(msg)
								// code 가 300번대면 이동안내
								if (code >= 300 && code < 400) {
									// if (import.meta.client) {
									// 	return response
									// 	// await Alert('redirect')
									// // throw new Error(msg)
									// }
								} else if (code === 401) {
									authStore.setLoggedIn(false)
								} else if (code === 1225) {
									authStore.setPasswordEntered(false)
								} else {
									console.log('---onResponse1:', code, msg)
									if (import.meta.client) {
										console.log('---onResponse2:', code, msg)
										throwApiError(code, msg)
										//throw new Error(msg)
										// await Alert(msg + '\n(' + code + ')' || '서버에서 요청하신 정보를 가져올 수 없습니다!')
									}
								}
								console.log('---onResponse3:', code, msg)
								throwApiError(code, msg)
								//throw new Error(msg)
								// code 가 400번대면 경고
								// code 가 500번대면 에러
							}
						}
					}
				},
				async onResponseError(_ctx) {
					console.log('---onResponseError:', _ctx)
				}
			}
			const finalOptions = defu(opts, defaults)
			// console.log('---finalOptions:', finalOptions.headers['ContentTypes-Type'])

			if (finalOptions.headers['Content-Type'] === 'multipart/form-data') {
				delete finalOptions.headers['Content-Type']
			}
			return finalOptions
		}

		const http = {
			async get<T>(url: string, options?: any): Promise<T> {
				return await $fetch(url, mergeOptions(url, { ...options, method: 'GET' }, false))
			},
			async post<T>(url: string, options?: any): Promise<T> {
				// const headers = useRequestHeaders(['cookie'])
				return await $fetch(url, mergeOptions(url, { ...options, method: 'POST', headers: useRequestHeaders(['cookie']) }, false))
			},
			async upload<T>(url: string, options?: any): Promise<T> {
				return await $fetch(url, mergeOptions(url, { ...options, method: 'POST', headers: useRequestHeaders(['cookie']) }, true))
			},
			async raw(url: string, options?: any) {
				return await $fetch.raw(url, mergeOptions(url, options, false))
			}
		}

		return {
			provide: { http }
		}
	},
	env: {
		islands: true
	}
})
