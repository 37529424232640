import { default as indexgrIKwSsnY6Meta } from "/app/home/pages/apps/index.vue?macro=true";
import { default as bakothereamzQiOzMMMeta } from "/app/home/pages/auth/login/bakother.vue?macro=true";
import { default as indexm2zjGluJAbMeta } from "/app/home/pages/auth/login/index.vue?macro=true";
import { default as mfamo4PPQEch6Meta } from "/app/home/pages/auth/login/mfa.vue?macro=true";
import { default as otherxDFO3iZCBMMeta } from "/app/home/pages/auth/login/other.vue?macro=true";
import { default as passwordFP6vJt8EqxMeta } from "/app/home/pages/auth/reset/password.vue?macro=true";
import { default as basicarxiTHZ5wsMeta } from "/app/home/pages/auth/signup/basic.vue?macro=true";
import { default as idKigB9d9SSuMeta } from "/app/home/pages/auth/signup/id.vue?macro=true";
import { default as indexZjPNC1ekvhMeta } from "/app/home/pages/auth/signup/index.vue?macro=true";
import { default as passwordXEw8MZL4KCMeta } from "/app/home/pages/auth/signup/password.vue?macro=true";
import { default as termsNhSNDdLtjSMeta } from "/app/home/pages/auth/signup/terms/terms.vue?macro=true";
import { default as errorsq3HievRxJJMeta } from "/app/src/pages/errors.vue?macro=true";
import { default as indexY2f10TJOYWMeta } from "/app/home/pages/feature/index.vue?macro=true";
import { default as applyYzQ0b72D6vMeta } from "/app/home/pages/home/apply.vue?macro=true";
import { default as index4zqWDHVNVaMeta } from "/app/home/pages/index.vue?macro=true";
import { default as rainLoginEE3t2TVmvmMeta } from "/app/home/pages/inquiry/counsel/rainLogin.vue?macro=true";
import { default as reservationAzoL3wuDQ4Meta } from "/app/home/pages/inquiry/counsel/reservation.vue?macro=true";
import { default as donehBaARIkXCKMeta } from "/app/home/pages/inquiry/done.vue?macro=true";
import { default as writeZELO3HQQ87Meta } from "/app/home/pages/inquiry/write.vue?macro=true";
import { default as login3hUYZUGxmUMeta } from "/app/src/pages/login.vue?macro=true";
import { default as commonTermsOXZcqm8EjsMeta } from "/app/home/pages/main/terms/commonTerms.vue?macro=true";
import { default as index2RjyRpl0iVMeta } from "/app/home/pages/my/bill/index.vue?macro=true";
import { default as indexlXYgR0L8VkMeta } from "/app/home/pages/my/certification/index.vue?macro=true";
import { default as indexjh6wnnUHNpMeta } from "/app/home/pages/my/index.vue?macro=true";
import { default as indexMEZLiRaDHwMeta } from "/app/home/pages/my/inquiry/index.vue?macro=true";
import { default as edit6rMsUG3ku6Meta } from "/app/home/pages/my/member/edit.vue?macro=true";
import { default as indexvmZNdrnNKeMeta } from "/app/home/pages/my/member/index.vue?macro=true";
import { default as mobileverifyFKiakk6fSrMeta } from "/app/home/pages/my/member/mobileverify.vue?macro=true";
import { default as donetsIK89P3lKMeta } from "/app/home/pages/my/member/withdraw/done.vue?macro=true";
import { default as identity2AW3HBmN2IMeta } from "/app/home/pages/my/member/withdraw/identity.vue?macro=true";
import { default as indexXioj1fbCA4Meta } from "/app/home/pages/my/member/withdraw/index.vue?macro=true";
import { default as reasonUAJ4Ycv0zoMeta } from "/app/home/pages/my/member/withdraw/reason.vue?macro=true";
import { default as indexqQ0VzFUiP5Meta } from "/app/home/pages/my/mfa/index.vue?macro=true";
import { default as password_checkiH7PySpnRAMeta } from "/app/home/pages/my/password_check.vue?macro=true";
import { default as completed6n7904FrYaMeta } from "/app/home/pages/my/subscription/cancel/completed.vue?macro=true";
import { default as form7lDbhLThdXMeta } from "/app/home/pages/my/subscription/cancel/form.vue?macro=true";
import { default as index7V4qe3yBi5Meta } from "/app/home/pages/my/subscription/cancel/index.vue?macro=true";
import { default as detail9Ntgqps8BgMeta } from "/app/home/pages/my/subscription/detail.vue?macro=true";
import { default as edit02kmFXBGt2Meta } from "/app/home/pages/my/subscription/edit.vue?macro=true";
import { default as indexeiD6Y1dveCMeta } from "/app/home/pages/my/subscription/index.vue?macro=true";
import { default as index5ivpaOKopZMeta } from "/app/home/pages/my/terms/index.vue?macro=true";
import { default as indexSPTuRrNqEDMeta } from "/app/home/pages/notice/[id]/index.vue?macro=true";
import { default as indexvYwPEFnmVLMeta } from "/app/home/pages/notice/index.vue?macro=true";
import { default as index6rjwCAPrhqMeta } from "/app/home/pages/service/index.vue?macro=true";
import { default as plandKsFwS8X8uMeta } from "/app/home/pages/service/plan.vue?macro=true";
import { default as doneK6T58gSzmMMeta } from "/app/home/pages/subscription/done.vue?macro=true";
import { default as indexLLUCdbvO2kMeta } from "/app/home/pages/subscription/index.vue?macro=true";
import { default as request3R9TIc8nthMeta } from "/app/home/pages/subscription/request.vue?macro=true";
import { default as termsphR2aVvnLzMeta } from "/app/home/pages/subscription/terms.vue?macro=true";
import { default as boardbKDaPuEj0MMeta } from "/app/home/pages/support/board.vue?macro=true";
import { default as detaild2aaF9ePALMeta } from "/app/home/pages/support/detail.vue?macro=true";
import { default as indexGaekznZxojMeta } from "/app/home/pages/support/index.vue?macro=true";
import { default as _91id_93Qr4YiHEr7kMeta } from "/app/home/pages/template/[id].vue?macro=true";
import { default as doneXuXhlWRcC4Meta } from "/app/home/pages/trial/done.vue?macro=true";
import { default as indexhW95ZwHlKEMeta } from "/app/home/pages/trial/index.vue?macro=true";
import { default as donecxZIWWUcMoMeta } from "/app/home/pages/withdraw/done.vue?macro=true";
export default [
  {
    name: "apps",
    path: "/apps",
    component: () => import("/app/home/pages/apps/index.vue").then(m => m.default || m)
  },
  {
    name: "auth-login-bakother",
    path: "/auth/login/bakother",
    component: () => import("/app/home/pages/auth/login/bakother.vue").then(m => m.default || m)
  },
  {
    name: "auth-login",
    path: "/auth/login",
    component: () => import("/app/home/pages/auth/login/index.vue").then(m => m.default || m)
  },
  {
    name: "auth-login-mfa",
    path: "/auth/login/mfa",
    component: () => import("/app/home/pages/auth/login/mfa.vue").then(m => m.default || m)
  },
  {
    name: "auth-login-other",
    path: "/auth/login/other",
    component: () => import("/app/home/pages/auth/login/other.vue").then(m => m.default || m)
  },
  {
    name: "auth-reset-password",
    path: "/auth/reset/password",
    component: () => import("/app/home/pages/auth/reset/password.vue").then(m => m.default || m)
  },
  {
    name: "auth-signup-basic",
    path: "/auth/signup/basic",
    component: () => import("/app/home/pages/auth/signup/basic.vue").then(m => m.default || m)
  },
  {
    name: "auth-signup-id",
    path: "/auth/signup/id",
    component: () => import("/app/home/pages/auth/signup/id.vue").then(m => m.default || m)
  },
  {
    name: "auth-signup",
    path: "/auth/signup",
    component: () => import("/app/home/pages/auth/signup/index.vue").then(m => m.default || m)
  },
  {
    name: "auth-signup-password",
    path: "/auth/signup/password",
    component: () => import("/app/home/pages/auth/signup/password.vue").then(m => m.default || m)
  },
  {
    name: "auth-signup-terms-terms",
    path: "/auth/signup/terms/terms",
    component: () => import("/app/home/pages/auth/signup/terms/terms.vue").then(m => m.default || m)
  },
  {
    name: "errors",
    path: "/errors",
    component: () => import("/app/src/pages/errors.vue").then(m => m.default || m)
  },
  {
    name: "feature",
    path: "/feature",
    component: () => import("/app/home/pages/feature/index.vue").then(m => m.default || m)
  },
  {
    name: "home-apply",
    path: "/home/apply",
    meta: applyYzQ0b72D6vMeta || {},
    component: () => import("/app/home/pages/home/apply.vue").then(m => m.default || m)
  },
  {
    name: "index",
    path: "/",
    component: () => import("/app/home/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "inquiry-counsel-rainLogin",
    path: "/inquiry/counsel/rainLogin",
    component: () => import("/app/home/pages/inquiry/counsel/rainLogin.vue").then(m => m.default || m)
  },
  {
    name: "inquiry-counsel-reservation",
    path: "/inquiry/counsel/reservation",
    component: () => import("/app/home/pages/inquiry/counsel/reservation.vue").then(m => m.default || m)
  },
  {
    name: "inquiry-done",
    path: "/inquiry/done",
    component: () => import("/app/home/pages/inquiry/done.vue").then(m => m.default || m)
  },
  {
    name: "inquiry-write",
    path: "/inquiry/write",
    component: () => import("/app/home/pages/inquiry/write.vue").then(m => m.default || m)
  },
  {
    name: "login",
    path: "/login",
    component: () => import("/app/src/pages/login.vue").then(m => m.default || m)
  },
  {
    name: "main-terms-commonTerms",
    path: "/main/terms/commonTerms",
    component: () => import("/app/home/pages/main/terms/commonTerms.vue").then(m => m.default || m)
  },
  {
    name: "my-bill",
    path: "/my/bill",
    component: () => import("/app/home/pages/my/bill/index.vue").then(m => m.default || m)
  },
  {
    name: "my-certification",
    path: "/my/certification",
    component: () => import("/app/home/pages/my/certification/index.vue").then(m => m.default || m)
  },
  {
    name: "my",
    path: "/my",
    component: () => import("/app/home/pages/my/index.vue").then(m => m.default || m)
  },
  {
    name: "my-inquiry",
    path: "/my/inquiry",
    component: () => import("/app/home/pages/my/inquiry/index.vue").then(m => m.default || m)
  },
  {
    name: "my-member-edit",
    path: "/my/member/edit",
    component: () => import("/app/home/pages/my/member/edit.vue").then(m => m.default || m)
  },
  {
    name: "my-member",
    path: "/my/member",
    component: () => import("/app/home/pages/my/member/index.vue").then(m => m.default || m)
  },
  {
    name: "my-member-mobileverify",
    path: "/my/member/mobileverify",
    component: () => import("/app/home/pages/my/member/mobileverify.vue").then(m => m.default || m)
  },
  {
    name: "my-member-withdraw-done",
    path: "/my/member/withdraw/done",
    component: () => import("/app/home/pages/my/member/withdraw/done.vue").then(m => m.default || m)
  },
  {
    name: "my-member-withdraw-identity",
    path: "/my/member/withdraw/identity",
    component: () => import("/app/home/pages/my/member/withdraw/identity.vue").then(m => m.default || m)
  },
  {
    name: "my-member-withdraw",
    path: "/my/member/withdraw",
    component: () => import("/app/home/pages/my/member/withdraw/index.vue").then(m => m.default || m)
  },
  {
    name: "my-member-withdraw-reason",
    path: "/my/member/withdraw/reason",
    component: () => import("/app/home/pages/my/member/withdraw/reason.vue").then(m => m.default || m)
  },
  {
    name: "my-mfa",
    path: "/my/mfa",
    component: () => import("/app/home/pages/my/mfa/index.vue").then(m => m.default || m)
  },
  {
    name: "my-password_check",
    path: "/my/password_check",
    component: () => import("/app/home/pages/my/password_check.vue").then(m => m.default || m)
  },
  {
    name: "my-subscription-cancel-completed",
    path: "/my/subscription/cancel/completed",
    component: () => import("/app/home/pages/my/subscription/cancel/completed.vue").then(m => m.default || m)
  },
  {
    name: "my-subscription-cancel-form",
    path: "/my/subscription/cancel/form",
    component: () => import("/app/home/pages/my/subscription/cancel/form.vue").then(m => m.default || m)
  },
  {
    name: "my-subscription-cancel",
    path: "/my/subscription/cancel",
    component: () => import("/app/home/pages/my/subscription/cancel/index.vue").then(m => m.default || m)
  },
  {
    name: "my-subscription-detail",
    path: "/my/subscription/detail",
    component: () => import("/app/home/pages/my/subscription/detail.vue").then(m => m.default || m)
  },
  {
    name: "my-subscription-edit",
    path: "/my/subscription/edit",
    component: () => import("/app/home/pages/my/subscription/edit.vue").then(m => m.default || m)
  },
  {
    name: "my-subscription",
    path: "/my/subscription",
    component: () => import("/app/home/pages/my/subscription/index.vue").then(m => m.default || m)
  },
  {
    name: "my-terms",
    path: "/my/terms",
    component: () => import("/app/home/pages/my/terms/index.vue").then(m => m.default || m)
  },
  {
    name: "notice-id",
    path: "/notice/:id()",
    component: () => import("/app/home/pages/notice/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: "notice",
    path: "/notice",
    component: () => import("/app/home/pages/notice/index.vue").then(m => m.default || m)
  },
  {
    name: "service",
    path: "/service",
    component: () => import("/app/home/pages/service/index.vue").then(m => m.default || m)
  },
  {
    name: "service-plan",
    path: "/service/plan",
    component: () => import("/app/home/pages/service/plan.vue").then(m => m.default || m)
  },
  {
    name: "subscription-done",
    path: "/subscription/done",
    component: () => import("/app/home/pages/subscription/done.vue").then(m => m.default || m)
  },
  {
    name: "subscription",
    path: "/subscription",
    component: () => import("/app/home/pages/subscription/index.vue").then(m => m.default || m)
  },
  {
    name: "subscription-request",
    path: "/subscription/request",
    component: () => import("/app/home/pages/subscription/request.vue").then(m => m.default || m)
  },
  {
    name: "subscription-terms",
    path: "/subscription/terms",
    component: () => import("/app/home/pages/subscription/terms.vue").then(m => m.default || m)
  },
  {
    name: "support-board",
    path: "/support/board",
    component: () => import("/app/home/pages/support/board.vue").then(m => m.default || m)
  },
  {
    name: "support-detail",
    path: "/support/detail",
    component: () => import("/app/home/pages/support/detail.vue").then(m => m.default || m)
  },
  {
    name: "support",
    path: "/support",
    component: () => import("/app/home/pages/support/index.vue").then(m => m.default || m)
  },
  {
    name: "template-id",
    path: "/template/:id()",
    component: () => import("/app/home/pages/template/[id].vue").then(m => m.default || m)
  },
  {
    name: "trial-done",
    path: "/trial/done",
    component: () => import("/app/home/pages/trial/done.vue").then(m => m.default || m)
  },
  {
    name: "trial",
    path: "/trial",
    component: () => import("/app/home/pages/trial/index.vue").then(m => m.default || m)
  },
  {
    name: "withdraw-done",
    path: "/withdraw/done",
    component: () => import("/app/home/pages/withdraw/done.vue").then(m => m.default || m)
  }
]